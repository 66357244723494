/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { FaBoxes } from "react-icons/fa";
import { useLocation } from "react-router";
import { Spinner } from "reactstrap";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import useDocumentTitle from "../Utility/useDocumentTitle";
import BoxTable from "./BoxTable";
import HamperContentsHeader from "./HamperContentsHeader";
import botBanner from '../../images/2024-Banner-Landing-ITSCHRISCODAY-AU.jpg';
import "./BoxContent.css";

export default function HamperContents() {
    const [document_title, setDocumentTitle] = useDocumentTitle("Chrisco - Hamper Contents");
    
    var location = useLocation();
    var year = null;
    var businessUnit = null;
    var hamperCode = null;

    var [isLoading, setIsLoading] = useState(false);
    var [hamperContents, setHamperContents] = useState();

    var pathComponents = location.pathname.split('/');

    if (pathComponents.length === 5) {
        year = pathComponents[2];
        businessUnit = pathComponents[3];
        hamperCode = pathComponents[4];
    }

    async function getContents() {
        setIsLoading(true);

        var uri = BASEAPI + "/api/boxContents/GetHamperContent/" + year + "/" + businessUnit + "/" + hamperCode
        var result = await GetJson(uri, null)
        .catch(function(error){
            console.log(error);
            //TODO: show error to user via toast or like
            //dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting reviews for term", errorMessage: error.message});
        })
        .finally(() => setIsLoading(false));

        if (result)
        {
            //result.skuItems.forEach(skuItem => {
            //    skuItem.inBox = false;
            //});
            setHamperContents(result);
            //setSkuItems(result.skuItems);
        }
    }

    useEffect(() => {
        getContents();
    },[year, businessUnit, hamperCode])

    return (
        <div>
            <HamperContentsHeader year={year} businessUnit={businessUnit} />
            
            {isLoading &&
                <Spinner color="secondary" />
            }

            {hamperContents &&
            <div>
                <div className="boxTitle"><FaBoxes /> {hamperContents.hamperName} [{hamperContents.hamperCode}] Hamper</div>

                <div className="boxSubTitle">Box List</div>
                {hamperContents.boxes.map((box, index) => {
                    return <BoxTable key={index} boxContents={box} inHamper={true} />
                })}
            </div>}
         <div>

            <div className="float-left"><img src={botBanner} alt="Chrisco Hampers" className="contentsBannerImage" /> </div>            

        </div>

        </div>
    )
}