import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo from '../../images/DacLogoInverted.png';
import FaFacebook from '../../images/Facebook_Logo.jpg';
import FaInstagramSquare from '../../images/Instagram_Logo.jpg';
import "./BoxContent.css";

export default function BoxContentsHeader({businessUnit}) {
    const isAu = businessUnit.toLowerCase() === "au";
    return (
        <Container fluid>
            <Row className="contentsBanner">
                <Col className="float-start contentsBannerTitle">Box Contents List</Col>
                <Col> <div className="float-right"><img src={logo} alt="Chrisco Hampers" className="contentsBannerLogo" /> </div></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col><div className="float-right contentHeaderSubtitle">{isAu ? 'www.chrisco.com.au' : 'www.chrisco.co.nz'} | {isAu ? '1800 830 830' : '0800 555 333'}</div></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col><div className="float-right contentHeaderSubtitle"><img src={FaInstagramSquare} alt="Instagram Logo" className="contentsInstaLogo" />{isAu ? '@chrisco.au' : '@chrisco.nz'} <img src={FaFacebook} alt="Facebook Logo" className="contentsFacebookLogo" />{isAu ? '/chrisco.au' : '/chrisco.newzealand'}</div></Col>
            </Row>
        </Container>
    )
}